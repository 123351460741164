.App {
  text-align: center;
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  width: 10px;
  background-color: #12151A;
}

::-webkit-scrollbar-thumb{
  background-color: #ABB8C3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #869199;
  transition: 0.5s;
}

::selection{
  color: #12151A;
  background-color: #ABB8C3;
}

img::selection{
  background-color: transparent;
}

img{
  -webkit-user-drag: none;
}