#about-section{
    width: 100%;
    height: 75vh;
    background-color: #DDD9D4;
}

.shape-img{
    width: 100%;
    position: relative;
    top: -50px;
}

.about-wrapper{
    width: 90%;
    height: auto;
    margin: auto;
}

.about-container{
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.about-content{
    width: 90%;
    margin: 0 auto;
    display: flex;
}

.about-img{
    width: 50%;
    height: 90%;
}

.about-img img {
    max-width: 350px;
    padding-bottom: 2rem;
    -webkit-user-drag: none;
}

.about-img img::selection{
    user-select: none;
}

.about-header{
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #151515;
    display: flex;
}

.about-p{
    font-family: montserrat, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #262524;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    margin: 2rem auto;
    text-align: left;
}
.about-p p{
    margin: 0;
}

.about-hr{
    height: 2px;
    width: 50%;
    background: #151515;
    /* background: linear-gradient(90deg, rgba(213, 162, 73, 0) 1%, rgba(213,161,73,1) 25%, rgba(213,161,73,1) 79%, rgba(213, 162, 73, 0) 100%); */
    border: none;    
}

.about-p::before{
    border: 2px solid #D5A149;
}

@media screen and (min-width: 2000px) {
    .shape-img {
        top: -65px;
    }
}

@media screen and (max-width: 1400px) {
    .about-wrapper{
        height: auto;
    }

    #about-section{
        height: auto;
    }
}


@media screen and (max-width: 1215px) and (min-width: 768px) {
    .shape-img {
        top: -20px;
        height: 35px;
    }

    #about-section{
        height: auto;
    }
    .about-wrapper{
        height: auto;
    }

    .about-header{
        margin: 0 0 2rem 0;
    }

    .about-content{
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-img{
        margin-top: 3rem;
    }

    .about-p{
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 767px) and (min-width: 481px) {
    #about-section{
        height: auto;
    }
    .about-wrapper{
        height: auto;
    }

    .about-header{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
    }

    .about-container {
        width: 100%;
    }
    
    .about-content{
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-img{
        margin-top: 3rem;
        display: flex;
        justify-content: center;
    }

    .about-img img{
        max-width: 250px;
    }

    .about-p{
        width: 100%;
        margin: auto;
        text-align: justify;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
    .shape-img {
        top: -20px;
        height: 35px;
    }

    #about-section{
        height: auto;
    }

    .about-wrapper{
        height: auto;
    }

    .about-header{
        font-size: 1rem;
        display: flex;
        justify-content: center;
    }

    .about-container {
        width: 100%;
    }
    
    .about-content{
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-img{
        margin-top: 3rem;
        display: flex;
        justify-content: center;
    }

    .about-img img{
        max-width: 250px;
    }

    .about-p{
        width: 100%;
        margin: auto;
        text-align: justify;
    }
}