#contact-section{
    width: 100%;
    height: auto;
    background-color: #DDD9D4;
}

form {
    width: auto;
}

.contact-wrapper{
    width: 90%;
    height: 80vh;
    margin: auto;
}

.contact-container{
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.contact-header{
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #151515;
    display: flex;
}

.contact-content{
    width: 60%;
    height: 80%;
    margin: auto;
    align-items: flex-start;
}

input {
    width: 80%;
    height: 5.5vh;
    margin: 1rem;
    border-radius: 15px;
    background-color: #313130;
    border: none;
    color: #ffffff;
}

 input:active{
    box-shadow: 0px 0px 15px 3px #292D30;
    color: #ffffff;
    outline: none;
    transition: 0.3s;
}

input:focus{
    /* border: 3px solid #04353D; */
    box-shadow: 0px 0px 15px 3px #292D30;
    color: #ffffff;
    outline: none;
    transition: 0.3s;
} 

textarea{
    width: 80%;
    height: 15vh;
    margin: 1rem;
    border-radius: 15px;
    background-color: #313130;
    border: none; 
    color: #ffffff;

}

textarea:active{
    box-shadow: 0px 0px 15px 3px #292D30;
    color: #ffffff;
    outline: none;
    transition: 0.3s;
} 

textarea:focus{
    box-shadow: 0px 0px 15px 3px #292D30;
    color: #ffffff;
    outline: none;
    transition: 0.3s;
} 

label{
    color: #ffffff;
}

::placeholder{
    font-family: montserrat, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #ABB8C3;
    padding-left: 1rem;
}

.contact-button{
    width: 14rem;
    height: 5.5vh;
    border-radius: 15px;
    /* border: 3px solid #D5A149; */
    /* background: rgb(255,210,0);
background: linear-gradient(180deg, rgba(255,210,0,1) 36%, rgba(255,186,0,1) 70%); */
background-color: #F1FE92;

    margin: 1rem auto;
    border: none;
    color: #12151A;
    font-size: 1.2rem;
    font-weight: 700;
}

.contact-button a{
    color: #12151A;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
}

.contact-button:hover{
    box-shadow: 0px 0px 10px 0px #F1FE92;
    transition: 0.3s;
    cursor: pointer;
    transform: 0;
    outline: 0;
}

.msg{
    color: #ABB8C3;
    font-size: 16px;
    display: none;
}

.error {
    color: red;
    font-size: 14px;
    display: block;
    font-display: left;
  }
  
.msgAppear{
    display: block;
}

@media screen and (max-width: 1200px) and (min-width:1024px) {
    .contact-content {
        width: 80%;
    }
}

@media screen and (max-width: 1023px) and (min-width:769px) {
    .contact-content {
        width: 100%;
    }
}

@media screen and (max-width: 820px) and (min-width: 481px) {

    .contact-header{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) and (min-width:481px) {
    .contact-content {
        width: 100%;
    }

    input {
        width: 80%;
    }

    textarea {
        width: 80%;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
    
    .contact-header{
        font-size: 1rem;
        display: flex;
        justify-content: center;
    }

    .contact-content {
        width: 100%;
    }

    input {
        width: 100%;
        margin: 0.5rem 0 0.5rem 0;
    }

    textarea {
        width: 100%;
        margin: 0.5rem 0 0.5rem 0;
    }
}