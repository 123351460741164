#skills-section{
    width: 100%;
    height: auto;
    margin: auto;
    /* display: flex;
    flex-direction: column; */
}
.skills-content{
    width: 70%;
    height: auto;
    margin: auto;
}

.skills-hr{
    width: 90%;
}

.skills-header{
    /* width: 80%; */
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #ABB8C3;
    display: flex;
    margin-top: 5rem;
}

#SkillBox {
    font-size: 20px;
    font-family: 'Indie Flower', cursive;
    width: 100%;
    height: auto;
    margin: 40px auto;
    background-color: transparent;
    /* border: 1px solid #cdcdcd; */
    padding: 10px;
    border-radius:20px;
    -o-border-radius:20px;
    -webkit-border-radius:20px;
    -ms-border-radius:20px;
    -moz-border-radius:20px;  
}
.SkillBar {
    width: 90%;
    height: 50px;
    position: relative;
    /* background: rgba(17, 17, 17, .3); */
    margin: 20px auto;
}
#Skill-HTML {
    width: 90%;
    margin: auto;
    animation: Animate-HTML 4s;
    -webkit-animation: Animate-HTML 4s;
    -moz-animation: Animate-HTML 4s;
    -o-animation: Animate-HTML 4s;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-HTML {
    from {
    width: 10px;
}
to {
    width: 90%}
}@-webkit-keyframes Animate-HTML {
    from {
    width: 10px;
}
to {
    width: 90%}
}@-moz-keyframes Animate-HTML {
    from {
    width: 10px;
}
to {
    width: 90%}
}@-o-keyframes Animate-HTML {
    from {
    width: 10px;
}
to {
    width: 90%}
}#Skill-CSS {
    animation: Animate-CSS 5s;
    -webkit-animation: Animate-CSS 5s;
    -moz-animation: Animate-CSS 5s;
    -o-animation: Animate-CSS 5s;
    width: 90%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-CSS {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-webkit-keyframes Animate-CSS {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-moz-keyframes Animate-CSS {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-o-keyframes Animate-CSS {
    from {
    width: 10px;
}
to {
    width: 80%}
}#Skill-jQuery {
    animation: Animate-jQuery 5s;
    -webkit-animation: Animate-jQuery 5s;
    -moz-animation: Animate-jQuery 5s;
    -o-animation: Animate-jQuery 5s;
    width: 85%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-jQuery {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-webkit-keyframes Animate-jQuery {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-moz-keyframes Animate-jQuery {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-o-keyframes Animate-jQuery {
    from {
    width: 10px;
}
to {
    width: 80%}
}#Skill-JS {
    animation: Animate-JS 4s;
    -webkit-animation: Animate-JS 4s;
    -moz-animation: Animate-JS 4s;
    -o-animation: Animate-JS 4s;
    width: 85%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-JS {
    from {
    width: 10px;
}
to {
    width: 75%}
}@-webkit-keyframes Animate-JS {
    from {
    width: 10px;
}
to {
    width: 75%}
}@-moz-keyframes Animate-JS {
    from {
    width: 10px;
}
to {
    width: 75%}
}@-o-keyframes Animate-JS {
    from {
    width: 10px;
}
to {
    width: 75%}
}#Skill-XML {
    animation: Animate-XML 4s;
    -webkit-animation: Animate-XML 4s;
    -moz-animation: Animate-XML 4s;
    -o-animation: Animate-XML 4s;
    width: 75%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-XML {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-webkit-keyframes Animate-XML {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-moz-keyframes Animate-XML {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-o-keyframes Animate-XML {
    from {
    width: 10px;
}
to {
    width: 70%}
}#Skill-C {
    animation: Animate-C 4s;
    -webkit-animation: Animate-C 4s;
    -moz-animation: Animate-C 4s;
    -o-animation: Animate-C 4s;
    width: 70%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-C {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-webkit-keyframes Animate-C {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-moz-keyframes Animate-C {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-o-keyframes Animate-C {
    from {
    width: 10px;
}
to {
    width: 80%}
}#Skill-JAVA {
    animation: Animate-JAVA 4s;
    -webkit-animation: Animate-JAVA 4s;
    -moz-animation: Animate-JAVA 4s;
    -o-animation: Animate-JAVA 4s;
    width: 80%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-JAVA {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-webkit-keyframes Animate-JAVA {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-moz-keyframes Animate-JAVA {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-o-keyframes Animate-JAVA {
    from {
    width: 10px;
}
to {
    width: 80%}
}#Skill-PHP {
    animation: Animate-PHP 4s;
    -webkit-animation: Animate-PHP 4s;
    -moz-animation: Animate-PHP 4s;
    -o-animation: Animate-PHP 4s;
    width: 65%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-PHP {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-webkit-keyframes Animate-PHP {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-moz-keyframes Animate-PHP {
    from {
    width: 10px;
}
to {
    width: 70%}
}@-o-keyframes Animate-PHP {
    from {
    width: 10px;
}
to {
    width: 70%}
}#Skill-SQL {
    animation: Animate-SQL 4s;
    -webkit-animation: Animate-SQL 4s;
    -moz-animation: Animate-SQL 4s;
    -o-animation: Animate-SQL 4s;
    width: 80%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;
}
@keyframes Animate-SQL {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-webkit-keyframes Animate-SQL {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-moz-keyframes Animate-SQL {
    from {
    width: 10px;
}
to {
    width: 80%}
}@-o-keyframes Animate-SQL {
    from {
    width: 10px;
}
to {
    width: 80%}
}#Skill-VBNET {
    animation: Animate-VBNET 4s;
    -webkit-animation: Animate-VBNET 4s;
    -moz-animation: Animate-VBNET 4s;
    -o-animation: Animate-VBNET 4s;
    width: 35%;
    height: 50px;
    position: absolute;
    background-color: #F1FE92;
    border-radius: 10px;;
}
@keyframes Animate-VBNET {
    from {
    width: 10px;
}
to {
    width: 35%}
}@-webkit-keyframes Animate-VBNET {
    from {
    width: 10px;
}
to {
    width: 35%}
}@-moz-keyframes Animate-VBNET {
    from {
    width: 10px;
}
to {
    width: 35%}
}@-o-keyframes Animate-VBNET {
    from {
    width: 10px;
}
to {
    width: 35%}
}.Skill-Area {
    z-index: 1;
    float: left;
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 2rem;
    text-shadow: none;
    color: #151515;
    font-family: Lato-Regular, sans-serif;
    font-size: 18px;
}
.PercentText {
    z-index: 3;
    position: relative;
    padding-right: 15px;
    margin-top: 15px;
    float: right;
    text-shadow: none;
    color: #ABB8C3;
    font-family: Lato-Regular, sans-serif;
    font-size: 18px;
}

@media screen and (max-width: 650px) {

    .SkillBar{
        width: 100%;
    }

     .Skill-Area{
       position: relative;
       margin-right: 0;
       font-size: 15px;
    }
    #SkillBox{
        width: 100%;
    }

    /* .PercentText{
        font-size: 1rem;
    }  */

    /* #Skill-HTML{
        width: 100%;
    }

    #Skill-CSS{
        width: 100%;
    }
    
    #Skill-jQuery{
        width: 100%;
    }

    #Skill-JS{
        width: 100%;
    }

    #Skill-XML{
        width: 100%;
    }

    #Skill-C{
        width: 100%;
    }

    #Skill-JAVA{
        width: 100%;
    }

    #Skill-PHP{
        width: 100%;
    } */
}

@media screen and (max-width: 820px) and (min-width: 481px) {
    .skills-header{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        margin-top: 3rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
    .skills-header{
        font-size: 1rem;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
}